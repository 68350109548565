import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../style/Appbar.css";
import React, { useState } from "react";
import "react-bootstrap-submenu/dist/index.css";
import { Row, Col, NavDropdown, Container, Navbar, Nav } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import Modal from "react-bootstrap/Modal";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Request a Demo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="rightside d-flex justify-content-center align-items-center">
          <iframe
            src="/form.html"
            width="100%"
            height="580px"
            title="form daat"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function NavScrollExample() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Navbar
      style={{ backgroundColor: "#0082bc", color: "#FFF" }}
      expand="lg"
      sticky="top"
      className="navbar"
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src={require("../asserts/logoW.png")}
            alt="logo"
            className="header__logo"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="justify-content-center flex-grow-1 pe-3 mt-2"
            style={{ color: "#FFF" }}
            navbarScroll
          >
            <Nav.Link
              className="nav-links"
              style={{ color: "#FFF", fontSize: "20px" }}
              href="/"
            >
              &nbsp;Home&nbsp;
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              style={{ color: "#FFF", fontSize: "20px" }}
              href="/story"
            >
              &nbsp;Our Story&nbsp;
            </Nav.Link>

            <NavDropdownMenu
              style={{ color: "#FFF", fontSize: "20px" }}
              className="nav-link"
              title="&nbsp;Products&nbsp;"
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item href="/Products">All Products</NavDropdown.Item>
              <NavDropdown.Divider />
              <DropdownSubmenu href="#" title="NMP Series">
                <NavDropdown.Item href="/nmp5">NMP 5 Plate</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/nmp7">NMP 7 Plate</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/nmp9">NMP 9 Plate</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/nmp11">NMP 11 Plate</NavDropdown.Item>
              </DropdownSubmenu>
              <NavDropdown.Divider />
              <DropdownSubmenu href="#" title="UCE Series">
                <NavDropdown.Item href="/uce">UCE 9 Plus</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/uce11">UCE 11 Plus</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/uce13">UCE 13 Plus</NavDropdown.Item>
              </DropdownSubmenu>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/ace">ACE-13</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/hybrid">
                Tyent H2 Hybrid{" "}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/watergen">
                Electrolyzed sterilizing water
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/soap">Tyent TM-X soap</NavDropdown.Item>
            </NavDropdownMenu>
            <NavDropdown.Divider />

            <Nav.Link
              className="nav-links"
              style={{ color: "#FFF", fontSize: "20px" }}
              href="/whytent"
            >
              &nbsp;Why Tyent&nbsp;
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              style={{ color: "#FFF", fontSize: "20px" }}
              href="/water"
            >
              &nbsp;Why Water Ionizer&nbsp;
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              style={{ color: "#FFF", fontSize: "20px" }}
              href="/testmonial"
            >
              &nbsp;Testmonials&nbsp;
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              style={{ color: "#FFF", fontSize: "20px" }}
              href="/benfits"
            >
              &nbsp;Benifits&nbsp;
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              style={{ color: "#FFF", fontSize: "20px" }}
              href="/contact"
            >
              &nbsp;Contact Us&nbsp;
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
            <Button variant="outline-light" onClick={() => setModalShow(true)}>
              Request a Demo
            </Button>

            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
